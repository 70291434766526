import axios from 'axios';

const roomRequest = axios.create({
	baseURL: 'https://checkinmaichau.com/api/v1',
  headers: {
    // Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const apiGetAllRooms = () => roomRequest.get('/data/rooms');
export const apiGetSingleRoom = id => roomRequest.get(`/data/room/${id}`);
export const apiPostBookingData = (id, data) => roomRequest.post(`/data/room/${id}`, data);
