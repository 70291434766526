const parseToVND = price => {
  if (!price) {
    return 0;
  }
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` + ' vnd';
};

module.exports = {
  parseToVND,
};
