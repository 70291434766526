/* eslint-disable global-require */
import React, { Component } from 'react';

const slideImages = [require('../../images/slide1.jpg')];

class HeroHeader extends Component {
  state = {
    urls: slideImages.map(image => null),
  };

  componentDidMount() {
    this.loadSlideImages();
  }

  loadSlideImages = () => {
    const { setSlidesAreLoaded } = this.props;

    slideImages.forEach((image, index) => {
      const totalImages = slideImages.length;
      const newImage = new Image();
      newImage.src = image;

      newImage.onload = () => {
        const urls = [...this.state.urls];
        urls[index] = image;
        this.setState({ urls });

        if (index === totalImages - 1) {
          setSlidesAreLoaded();
        }
      };
    });
  };

  render() {
    return (
      <div>
        <header className="main__hero">
          <div className="hero__info">
            <div className="hero__info-wrapper">
              <img
                src="/images/checkinmaichau-logo.png"
                alt="White space"
                className="hero__info-logo"
              />
              <div className="hero__info-content">
                <div className="hero__info-left">
                  <img
                    src="/images/hero-instagram-brands.svg"
                    alt="White space's instagram"
                    className="hero__info-social-icon"
                  />
                  <img
                    src="/images/hero-facebook-square-brands.svg"
                    alt="White space's facebook"
                    className="hero__info-social-icon"
                  />
                </div>
                <div className="hero__info-right">
                  <div className="hero__info-item">
                    <img
                      src="/images/hero-phone-alt-solid.svg"
                      alt="tel"
                      className="hero__info-contact-icon"
                    />
                    <span className="hero__info-text">0981674540</span>
                  </div>
                  <div className="hero__info-item">
                    <img
                      src="/images/hero-envelope-solid.svg"
                      alt="email"
                      className="hero__info-contact-icon"
                    />
                    <span className="hero__info-text">checkinmaichau@gmail.com</span>
                  </div>
                  <div className="hero__info-item">
                    <img
                      src="/images/hero-home-solid.svg"
                      alt="address"
                      className="hero__info-contact-icon"
                    />
                    <span className="hero__info-text">
                      Bản Lác, Chiềng Châu, Mai Châu, Hòa Bình
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hero__slides">
            {this.state.urls.map((img, i) => (
              <div key={i} className="hero__slide" style={{ backgroundImage: `url(${img})` }} />
            ))}
          </div>
        </header>
      </div>
    );
  }
}

export default HeroHeader;
