import React from 'react';
import {parseToVND} from '../../helper/utils';

import { Ellipsis } from 'react-spinners-css';

const RoomPrice = ({ roomIsLoading, normalDayPrice, holidayPrice }) => {
  return (
    <div className="booking-card__prices">
      <div className="booking-card__price-item">
        <span className="booking-card__price-title">Ngày trong tuần</span>
        <span className="booking-card__price-per-night">
          {roomIsLoading ? <Ellipsis color="#111" /> : `${parseToVND(normalDayPrice)}`}
        </span>
      </div>
      <div className="booking-card__price-item">
        <span className="booking-card__price-title">Ngày cuối tuần</span>
        <span className="booking-card__price-per-night">
          {roomIsLoading ? <Ellipsis color="#111" /> : `${parseToVND(holidayPrice)}`}
        </span>
      </div>
    </div>
  );
};

export default RoomPrice;
